import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import "./styles.scss";
function CaseStudyCard({
	slug,
	platformImage,
	title,
	tagline,
	heroImage,
	brief,
}) {
	const heroImageData = getImage(heroImage);

	return (
		<Link className="case-study-card" to={`/${slug}`}>
			<div className="case-study-card__image">
				{heroImageData && (
					<GatsbyImage
						image={heroImageData}
						alt={
							heroImage.description ? heroImage.description : heroImage.title
						}
					/>
				)}
			</div>
			<div className="case-study-card__info">
				<h2 className="case-study-card__title">{title}</h2>
				<div className="case-study-card__tagline">{tagline}</div>
				<p>{brief}</p>
				{platformImage && (
					<>
						<div className="case-study-card__platform">Platform</div>
						{platformImage?.file?.url && (
							<img
							className="case-study-card__platform-image"
								src={platformImage.file.url}
								alt={
									platformImage.description
										? platformImage.description
										: platformImage.title
								}
							/>
						)}
					</>
				)}
			</div>
		</Link>
	);
}

export default CaseStudyCard;

CaseStudyCard.propTypes = {
	title: PropTypes.string,
	tag: PropTypes.string,
	brief: PropTypes.string,
	slug: PropTypes.string,
	platformImage: PropTypes.shape({
		description: PropTypes.string,
		gatsbyImageData: PropTypes.object,
		title: PropTypes.string,
	}),
	heroImage: PropTypes.shape({
		description: PropTypes.string,
		gatsbyImageData: PropTypes.object,
		title: PropTypes.string,
	}),
};
