import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import CaseStudyCard from "../../components/CaseStudyCard";
import Polygon from "./polygon.svg";
import "./styles.scss";

function WorkPage() {
	const data = useStaticQuery(graphql`
		query {
			contentfulWorkPage {
				heading
				caseStudies {
					contentful_id
					slug
					platform
					title
					tagline
					heroImage {
						gatsbyImageData(width: 600, placeholder: NONE)
						title
						description
					}
					platformImage {
						file {
							contentType
							url
							fileName
							details {
								image {
									width
									height
								}
							}
						}
					}
					brief {
						brief
					}
				}
			}
		}
	`);

	const { heading, caseStudies } = data.contentfulWorkPage;

	return (
		<div className="work">
			<Layout>
				<SearchEngineOptimization title="Work" />
				<section className="section section--is-spacious">
					<div className="container">
						<h1 className="work__heading">
							{heading}
							<div className="work__polygon">
								<img src={Polygon} alt="" aria-hidden="true" />
							</div>
						</h1>
						<div className="work__case-studies">
							{caseStudies.map(caseStudy => {
								const {
									contentful_id,
									slug,
									platformImage,
									title,
									tagline,
									heroImage,
									brief: { brief },
								} = caseStudy;

								return (
									<CaseStudyCard
										contentful_id={contentful_id}
										slug={slug}
										platformImage={platformImage}
										title={title}
										tagline={tagline}
										heroImage={heroImage}
										brief={brief}
										key={contentful_id}
									/>
								);
							})}
						</div>
					</div>
				</section>
			</Layout>
		</div>
	);
}

export default WorkPage;
